var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"stock-threshold","bladeName":"stock-thresholds","bladesData":_vm.bladesData,"canAdd":"","canDelete":"","canExportCSV":"","getParams":_vm.getParams,"headers":[
        { text: 'Location', value: 'location', display: true, csv: true, subtitle: 0 },
        { text: 'Product', value: 'product', display: true, csv: true, title: 1 },
        { text: 'Min', value: 'lowerThreshold', textFilter: 'toDisplayNumber', subtitle: 1, prefix: 'Min: ' },
        { text: 'Max', value: 'upperThreshold', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Max: ' }],"navigation":"stock-thresholds","onPullSuccessAsync":_vm.filterByProductsAsync,"title":"Stock Thresholds","useServerPagination":""},on:{"sourceChanged":_vm.updateBlade},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-toolbar-title',[_c('BT-Entity',{attrs:{"navigation":"locations","itemValue":_vm.mLocationID,"itemText":"locationName"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',[_vm._v(": Thresholds")])]},proxy:true}])})],1)]},proxy:true},{key:"location",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"navigation":"locations","itemValue":item.locationID,"itemText":"locationName"}})]}},{key:"product",fn:function(ref){
        var item = ref.item;
return [_c('BT-Entity',{attrs:{"navigation":"products","itemValue":item.productID,"itemText":"productName"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }